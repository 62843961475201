"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@material-ui/core");
var useCreateLinkFormStyles = (0, core_1.makeStyles)(function (theme) {
    var _a, _b, _c, _d, _e;
    return (0, core_1.createStyles)({
        endAdornment: {
            flexShrink: 0,
        },
        form: {
            display: 'flex',
            flexDirection: 'column',
            marginBottom: theme.spacing(11.75),
        },
        labelText: {
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(1),
        },
        outlinedInput: {
            padding: theme.spacing(0),
            borderRadius: '8px',
            borderColor: '#11111333',
        },
        outlinedTagsTextInput: {
            padding: theme.spacing(0),
            flexWrap: 'wrap',
        },
        input: {
            flexGrow: 1,
            height: '100%',
            minHeight: function (props) { return props.textFieldHeight; },
            padding: theme.spacing(0),
            lineHeight: 1.5,
        },
        fileInput: (_a = {
                flexGrow: 1,
                height: '100%',
                minHeight: function (props) { return props.textFieldHeight; },
                padding: theme.spacing(0),
                lineHeight: 1.5,
                backgroundColor: theme.palette.divider,
                display: 'flex',
                alignItems: 'stretch',
                overflow: 'hidden',
                justifyContent: 'space-between',
                borderRadius: '3px'
            },
            _a[theme.breakpoints.up('sm')] = {
                backgroundColor: '#e8e8e8',
            },
            _a),
        tagsTextInput: {
            flexGrow: 1,
            height: '100%',
            minHeight: function (props) { return props.textFieldHeight; },
            width: 0,
            minWidth: '50px',
            padding: theme.spacing(0),
            marginLeft: theme.spacing(1),
            lineHeight: 1.5,
        },
        button: (_b = {
                height: '44px',
                margin: theme.spacing(6, 0, 2),
                padding: theme.spacing(1, 0),
                alignSelf: 'center',
                minWidth: '180px'
            },
            _b[theme.breakpoints.up('sm')] = {
                alignSelf: 'flex-end',
                marginTop: theme.spacing(6.25),
                minWidth: '148px',
            },
            _b),
        shortUrlInput: {
            width: '100%',
        },
        tagsText: {
            width: '100%',
        },
        inputNotchedOutline: function (props) {
            return props.createShortLinkError ? { border: 'solid 2px #c85151' } : {};
        },
        refreshIcon: {
            marginRight: theme.spacing(1),
            fill: theme.palette.primary.dark,
        },
        iconTest: {
            '&.svg': {
                fill: '#f00',
            },
        },
        linkTypeButton: (_c = {
                // width: '164px',
                height: '40px',
                borderRadius: '46px',
                margin: 4
            },
            _c[theme.breakpoints.down('xs')] = {
                maxWidth: '31%',
                width: 'auto',
                height: 'auto',
            },
            _c),
        linkTypeButtonEnabled: {
            backgroundColor: '#FFC700 !important',
            color: theme.palette.primary.main,
        },
        linkTypeButtonText: (_d = {
                marginLeft: '4px'
            },
            _d[theme.breakpoints.down('xs')] = {
                fontSize: 11,
            },
            _d),
        linkTypeWrapper: (_e = {
                marginTop: theme.spacing(5),
                background: 'white',
                border: '2px solid #394B53',
                borderRadius: '46px',
                width: 'fit-content',
                overflow: 'hidden'
            },
            _e[theme.breakpoints.up('sm')] = {
                marginTop: theme.spacing(4),
            },
            _e),
        fileInputInvis: {
            display: 'none',
        },
        fileInputDescWrapper: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
        },
        fileInputWrapper: {
            width: '100%',
            display: 'flex',
            alignItems: 'stretch',
            borderRadius: '3px',
            overflow: 'hidden',
            border: function (props) {
                return props.uploadFileError ? '2px solid #C85151' : '';
            },
        },
        bulkUploadDescWrapper: {
            marginTop: '32px',
            backgroundColor: '#FFC70064',
            color: theme.palette.text.primary,
            paddingLeft: '18px',
            paddingTop: '12px',
            paddingBottom: '12px',
            display: 'flex',
            borderRadius: 10,
        },
        bulkUploadDescText: {
            paddingLeft: '20px',
        },
        bulkSampleDownloadText: {
            fontWeight: 'bold',
            textDecoration: 'underline',
            cursor: 'pointer',
            background: 'none!important',
            border: 'none',
            padding: '0!important',
            fontSize: '1rem',
            lineHeight: '1.5625',
        },
        maxSizeText: {
            marginLeft: '4px',
            fontWeight: 500,
        },
        maxSizeTextWrapper: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            display: 'flex',
            alignItems: 'stretch',
        },
        leftFileIcon: {
            width: '44px',
            backgroundColor: theme.palette.primary.main,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        fileNameText: {
            fontWeight: 400,
            paddingLeft: '16px',
            display: 'flex',
            alignItems: 'center',
        },
        shortLinkError: {
            color: 'black',
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'underline',
            },
        },
    });
});
exports.default = useCreateLinkFormStyles;
